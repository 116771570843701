import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import ChicagoTeam from "../images/chicagoTeam.jpg"

const TeamChicagoPage = () => (
  <>
    <SEO title="Chicago Location" />
    <div className="hero-section in-chicago">
      <div className="cover-overlay"></div>
      <div className="overlay-text">
        <h3 className="is-white font-size-42 font-weight-bold">Ketamine Centers of Chicago</h3>
        <p className="is-white font-size-20">Meet Our Amazing Chicago Team</p>
      </div>
    </div>
    <div className="container is-white pt-4 pb-4">
    <h2 className="is-purple pt-4 font-weight-bold text-center">IV Solution and Ketamine Centers of Chicago and Kansas City</h2>
    <h3 className="is-purple pt-4 text-center">We are a state of the art medical facility where you will undergo your customized treatment in a very private, luxurious setting that is unlike any other.</h3>
    <h2 className="is-purple pt-4 font-weight-bold text-center">Helping You Get Better</h2>
    <p className="is-purple font-weight-bold text-center">Specialists in their fields are here to aid you</p>
        <div className="row mb-4 mt-4 align-items-center">
            <div className="col-sm p-4" style={{ borderRadius: '6px' }}>
                <img style={{ width: '100%', borderRadius: '6px' }} src={ChicagoTeam} alt="Chicago Team" />
            </div>
            <div className="col-sm d-flex justify-content-center flex-column pl-4">
                <h3 className="is-purple pt-4 font-weight-bold">IV Solution &amp; Ketamine Centers of Chicago and Kansas City</h3>
                <p className="is-purple pt-4">Our premier clinic offers innovative and effective intravenous medical treatment for treatment resistant depression (TRD), anxiety, chronic pain, fibromyalgia, OCD, PTSD and more. As the top ketamine treatment center in Chicago, you can expect state of the art care from a team of experienced medical professionals and physicians.</p>
                <h3 className="is-purple pt-4 font-weight-bold">Experienced Team of Medical Professionals</h3>
                <p className="is-purple pt-4">Our team is spearheaded by Dr. Bal Nandra, trained at the prestigious University of Chicago Hospitals Department of Anesthesia and Critical Care with nearly 20 years of experience in a clinical setting. Dr. Nandra has meticulously assembled a top-notch team of registered nurses with ketamine infusion experience, along with licensed therapists and technicians who care for patients and collaborate with their primary care physician, specialist, or mental health professional to optimize treatment and ensure continuity of care.</p>
                <h3 className="is-purple pt-4 font-weight-bold">Safe &amp; Effective Ketamine Therapy</h3>
                <p className="is-purple pt-4">Our team brings this proven therapy to patients in a safe and comfortable outpatient setting, providing ketamine infusion therapy services using effective protocols in accordance with the highest industry standards. Our team is committed to providing the best patient outcomes to improve quality of life and well-being.</p>
                <Link className="btn btn-success my-2 my-sm-0 pl-4 pr-4 pt-3 pb-3" to="/">Schedule a Consultation</Link>
            </div>
        </div>
        <iframe title="Google Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.8849188590016!2d-87.63222188455835!3d41.89533197922083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880fd34d34a1be73%3A0x1c3864d56cfd2e6!2s712%20N%20Dearborn%20St%2C%20Chicago%2C%20IL%2060654!5e0!3m2!1sen!2sus!4v1576608973296!5m2!1sen!2sus" width="100%" height="450" frameborder="0" style={{border:0, borderRadius:6 }} allowfullscreen=""></iframe>
    </div>
  </>
)

export default TeamChicagoPage
